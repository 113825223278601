<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ communication.communication_type }} - {{ $t("COMMON.ATTACHEMENTS") }}</h3>
    <div class="row" style="">
      <div class="col-3" v-for="(attachement, key) in communication.attachements" :key="key">
        <a v-bind:href="attachement" target="_blank">
          <img :src="`${attachement}`" class="gallery-image" v-if="attachement.includes('png') || attachement.includes('jpg') || attachement.includes('jpeg') || attachement.includes('gif') || attachement.includes('svg')" />
        <img :src="`/img/icons/files/text.png`" class="gallery-image" v-else-if="attachement.includes('txt')" />
        <img :src="`/img/icons/files/pdf.png`" class="gallery-image" v-else-if="attachement.includes('pdf')" />
        <img :src="`/img/icons/files/doc.png`" class="gallery-image" v-else-if="attachement.includes('docx') || attachement.includes('doc')" />
        <img :src="`/img/icons/files/xls-file.png`" class="gallery-image" v-else-if="attachement.includes('xlsx') || attachement.includes('xlsm') || attachement.includes('xlsb') || attachement.includes('xltx') || attachement.includes('csv')" />
        <img :src="`/img/icons/files/default.png`" class="gallery-image" v-else />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "communication-view-attachements",

  components: {},

  props: ["communication"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    product(product) {},
  },
};
</script>
<style lang="scss" scoped>
  .col-3 img{
    width: 100px;
  }
</style>
