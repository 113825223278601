<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ communication.communication_type }}</h3>
    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COMMUNICATION_TYPE") }}</dt>
          <dd class="col-sm-8">
            {{$t(`COMMUNICATIONS.COMMUNICATIONS_TYPE_${communication.communication_type}`)}}
          </dd>
        </dl>
        <dl class="row" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)">
          <dt class="col-sm-4">{{ $t("COMMON.CONTACT") }}</dt>
          <dd class="col-sm-8">
            <contact :contact="communication.contact" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="communication.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COMMUNICATION_DATE") }}</dt>
          <dd class="col-sm-8" v-if="communication.date">
            {{
              $formatDate(communication.date, "dddd D MMMM YYYY HH:mm")
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMUNICATIONS.TRAKING_NUMBER") }}</dt>
          <dd class="col-sm-8">
            <div v-html="communication.tracking_number"></div>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="communication.organization" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="communication.created_at">
            {{ $formatDate(communication.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="communication.updated_at">
            {{ $formatDate(communication.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>

import Contact from "@/components/Contact.vue";
export default {
  name: "communication-view-global",

  components: {
    Contact
  },

  props: ["communication"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    communication(communication) {},
  },
};
</script>
